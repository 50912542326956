@use '@angular/material' as mat;
@include mat.core();

$primary-color: (
        0: #211F7E,
        100: #211F7E,
        200: #211F7E,
        300: #211F7E,
        400: #211F7E,
        500: #211F7E,
        600: #211F7E,
        700: #211F7E,
        800: #211F7E,
        900: #211F7E,
        contrast: (50: #ffffff)
);
$secondary-color: (
        0: #3086ED,
        100: #3086ED,
        200: #3086ED,
        300: #3086ED,
        400: #3086ED,
        500: #3086ED,
        600: #3086ED,
        700: #3086ED,
        800: #3086ED,
        900: #3086ED,
        contrast: (50: #ffffff)
);
$warn-color: (
        0: #BD640D,
        100: #BD640D,
        200: #BD640D,
        300: #BD640D,
        400: #BD640D,
        500: #BD640D,
        600: #BD640D,
        700: #BD640D,
        800: #BD640D,
        900: #BD640D,
        contrast: (50: #ffffff)
);
$my-app-primary: mat.define-palette($primary-color);
$my-app-accent: mat.define-palette($secondary-color);
$my-app-warn: mat.define-palette($warn-color);
$my-app-theme: mat.define-light-theme((
        color: (
                primary: $my-app-primary,
                accent: $my-app-accent,
                warn: $my-app-warn
        ),
        typography: mat.define-typography-config(),
        density: 0,
));

@include mat.all-component-themes($my-app-theme);

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 9999 !important;
}
