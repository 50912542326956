@landing-portal-color: @global-primary-background;
@landing-inverse-color: @global-inverse-color;
@landing-z-index: @global-z-index - 20;

@landing-header-height: @global-header-height;
@landing-sidebar-height: calc(100vh - @landing-header-height);

@landing-left-sidebar-width: 100px;
@landing-left-sidebar-background: @global-inverse-color;
@landing-left-sidebar-border-width: @global-border-width;
@landing-left-sidebar-border: @global-border;

@landing-right-sidebar-min-width: 360px;
@landing-right-sidebar-background: @table-color;

@landing-action-button-background: @global-background;
@landing-action-button-border-width: @global-border-width;
@landing-action-button-border: transparent;
@landing-action-button-border-radius: @global-small-border-radius;
@landing-action-button-hover-background: @global-background;

@landing-action-button-portal-background: @landing-portal-color;
@landing-action-button-portal-color: @landing-inverse-color;
@landing-action-button-portal-border: @landing-action-button-portal-background;
@landing-action-button-portal-hover-background: @global-background;
@landing-action-button-portal-hover-color: @landing-portal-color;

@landing-action-button-orcid-color: @orcid-color;
@landing-action-button-orcid-border: @orcid-color;
@landing-action-button-orcid-disabled-color: @global-muted-color;
@landing-action-button-orcid-disabled-border: @global-muted-color;

@landing-section-min-height: 60vh;
@landing-section-border-width: 3px;
@landing-section-gutter-vertical: @global-large-gutter;
@landing-section-gutter-horizontal: @global-small-margin;
@landing-section-separator: fade(@landing-portal-color, @global-opacity);

@landing-tab-background: @global-muted-background;
@landing-tab-border-horizontal-width: @global-border-width;
@landing-tab-border-horizontal: @global-border;
@landing-tab-border-top-width: 4px;
@landing-tab-border-radius: @global-small-border-radius;
@landing-tab-background-active: @global-background;
@landing-tab-color-active: @landing-portal-color;
@landing-tab-border-active: @landing-portal-color;

@landing-card-tab-border-radius: @global-border-radius;
@landing-card-tab-border-width: 5px;

@landing-action-bar-padding: 10px 30px;
@landing-action-bar-grid-gap: 20px;
@landing-action-bar-border-width: @global-border-width;
@landing-action-bar-border-radius: @global-small-border-radius;

@landing-metrics-grip-gap: 80px;
@landing-metrics-background-color: @openaire-secondary-color;
@landing-metrics-background-svg: '<svg id="Layer_1-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="326" height="155.391" viewBox="0 0 326 155.391">  <defs>    <clipPath id="clip-path">      <rect id="Rectangle_17830" data-name="Rectangle 17830" width="326" height="155.39" fill="none"/>    </clipPath>  </defs>  <g id="Group_15222" data-name="Group 15222">    <rect id="Rectangle_17829" data-name="Rectangle 17829" width="326" height="155.39" rx="15" fill="@landing-metrics-background-color"/>    <g id="Group_15221" data-name="Group 15221" clip-path="url(#clip-path)">      <circle id="Ellipse_868" data-name="Ellipse 868" cx="181.05" cy="181.05" r="181.05" transform="translate(-129.306 -88.381)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1" opacity="0.72"/>      <circle id="Ellipse_869" data-name="Ellipse 869" cx="204.367" cy="204.367" r="204.367" transform="translate(-153.59 -135.71)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1" opacity="0.72"/>      <circle id="Ellipse_870" data-name="Ellipse 870" cx="181.05" cy="181.05" r="181.05" transform="translate(-82.804 -115.955)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.75" opacity="0.72"/>      <circle id="Ellipse_871" data-name="Ellipse 871" cx="181.05" cy="181.05" r="181.05" transform="translate(-56.26 -128.15)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.5" opacity="0.72"/>      <path id="Path_119069" data-name="Path 119069" d="M-38.064-5.565a180.54,180.54,0,0,1,.745-69.415C-17.165-172.917,78.573-235.972,176.51-215.819S337.5-99.927,317.348-1.99,201.456,159,103.52,138.848A180.614,180.614,0,0,1-17.462,50.859" transform="translate(15.132 80.898)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.25" opacity="0.72"/>    </g>  </g></svg>';

@landing-action-bar-mobile-border: @global-border;

.landing {
  #landing-center-content {
    min-height: @landing-sidebar-height;
  }

  #landing-left-sidebar {
    width: @landing-left-sidebar-width;
    background: @landing-left-sidebar-background;
    border-right: @landing-left-sidebar-border-width solid @landing-left-sidebar-border;

    & > .uk-sticky {
      height: @landing-sidebar-height;
    }
  }

  #landing-right-sidebar {
    min-width: @landing-right-sidebar-min-width;
    background: @landing-right-sidebar-background;

    & > .uk-sticky {
      height: @landing-sidebar-height;
    }
  }

  #landing-right-sidebar-switcher {
    top: 50vh !important;
  }

  #main-tabs-div {
    z-index: @landing-z-index - 2;
  }

  #graph_and_feedback {
    z-index: @landing-z-index - 1;
  }

  #landing-sections {
    .landing-section {
      padding: (@landing-section-gutter-vertical/2) @landing-section-gutter-horizontal @landing-section-gutter-vertical @landing-section-gutter-horizontal;

      &:not(:first-child) {
        margin-top: @landing-section-gutter-vertical;
      }

      &:not(.landing-section-height-auto) {
        min-height: @landing-section-min-height;
      }

      &:not(:last-child) {
        border-bottom: @landing-section-border-width solid @landing-section-separator;
      }
    }
  }

  .landing-action-button {
    &:extend(.uk-icon-button-small);
    background-color: @landing-action-button-background;
    border: @landing-action-button-border-width solid @landing-action-button-border;
    border-radius: @landing-action-button-border-radius;

    &:hover {
      background: @landing-action-button-hover-background;
      border: transparent;
    }

    &.landing-action-button-portal {
      background-color: @landing-action-button-portal-background;
      color: @landing-action-button-portal-color;
      border-color: @landing-action-button-portal-border;

      &:hover {
        background: @landing-action-button-portal-hover-background;
        color: @landing-action-button-portal-hover-color;
      }
    }

    &.landing-action-button-orcid {
      color: @landing-action-button-orcid-color;
      border-color: @landing-action-button-orcid-border;

      &.uk-disabled {
        color: @landing-action-button-orcid-disabled-color;
        border-color: @landing-action-button-orcid-disabled-border;
      }
    }
  }

  .landing-tab:extend(.uk-text-small) {
    background: @landing-tab-background;
    border-radius: @landing-tab-border-radius;
    border-left: @border-width;

    & .uk-tab {

      & > * > a {
        border-bottom: none;
        border-left: @landing-tab-border-horizontal-width solid @landing-tab-border-horizontal;
        border-right: @landing-tab-border-horizontal-width solid @landing-tab-border-horizontal;
        border-top: @landing-tab-border-top-width solid transparent;
      }

      & > .uk-active > a {
        border-radius: @landing-tab-border-radius 0 0 0;
        border-top: @landing-tab-border-top-width solid @landing-tab-border-active;
        background: @landing-tab-background-active;
        color: @landing-tab-color-active;
        position: relative;
      }
    }
  }

  .uk-dropdown {
    z-index: @landing-z-index + 1;
  }

  .card-tab {
    border-radius: @landing-card-tab-border-radius;
    border-left: @landing-card-tab-border-width solid @landing-section-separator;
  }

  .landing-action-bar {
    padding: @landing-action-bar-padding;
    border-radius: @landing-action-bar-border-radius;
    border: @landing-portal-color solid @landing-action-bar-border-width;

    & .uk-grid-divider > :not(.uk-first-column)::before {
      border-color: @disable-color;
      top: 4px;
      bottom: 4px;
    }
  }

  // TODO: when we get there
  .landing-action-bar-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: @global-z-index - 20;

    & > div {
      padding: 8px 20px;
      border-top: @landing-action-bar-border-width solid @landing-action-bar-mobile-border;
    }
  }

  .landing-metrics-overview {
    grid-gap: @landing-metrics-grip-gap;
  }

  .landing-metrics-card:extend(.uk-card):extend(.uk-card-secondary all) {
    background-repeat: no-repeat, no-repeat;
    @svg: escape(replace(@landing-metrics-background-svg, "@landing-metrics-background-color", @landing-metrics-background-color));
    background-image: url("../metrics-badge.svg"), url("data: image/svg+xml, @{svg}");
    background-position: calc(100% - @card-body-padding-vertical) calc(100% - @card-body-padding-horizontal), center;
    background-blend-mode: color-dodge;
    background-size: auto, cover;
    background-color: unset;
    min-height: 78px;
  }

  .landing-oaroutes-card:extend(.uk-card):extend(.uk-card-default all) {
    background-repeat: no-repeat, no-repeat;
    background-image: url("../open-access.svg");
    background-position: calc(100%) calc(100%), center;
    background-size: auto, contain;
    height: 108px;
  }

    // rules for overriding styles of altmetrics embedded library
  .altmetric-embed {
    position: relative !important;
    z-index: 1 !important;
  }

  .altmetric-normal-legend {
    margin: 0 40px 0 0 !important;

    .altmetric-see-more-details {
      display: none !important;
    }
  }

  .altmetric-popover-content {
    background-color: unset !important;
    font-size: unset !important;
    font-family: unset !important;
  }
}
